.contact {
  position: relative;
  padding-top: 10rem;
}

.contact h2 {
  text-align: center;
  font-style: italic;
  font-weight: 300;
  font-size: 5rem;
  padding-bottom: 2rem;
}

.contact .map {
  width: 65vw;
  height: auto;
  padding-left: 10rem;
}

.contact .contact_aside {
  position: absolute;
  top: 23rem;
  right: 10rem;
  width: 35vw;
  background-color: #cb1411;
  padding-left: 4rem;
  text-align: left;
}

.contact .contact_aside h2 {
  font-size: 5rem;
  color: #fff;
  padding: 6rem 0 3rem;
  font-style: italic;
  font-weight: 300;
}

.contact .contact_aside h3 {
  font-size: 3rem;
  color: #fff;
}

.contact .contact_aside p {
  color: white;
  font-size: 1.4rem;
  padding: 1rem 0rem;
}

.contact .contact_aside p:last-of-type {
  padding-bottom: 6rem;
}

@media (max-width: 1300px) {
  .contact .contact_aside {
    top: 20rem;
    padding-left: 2rem;
  }

  .contact .contact_aside h2 {
    padding-top: 3rem;
  }

  .contact .contact_aside p:last-of-type {
    padding-bottom: 3rem;
  }
}

@media (max-width: 1100px) {
  .contact .contact_aside h2 {
    font-size: 4rem;
    padding: 2rem 2rem;
  }

  .contact .contact_aside p {
    padding: 0.5rem 0;
  }
}

@media (max-width: 1100px) {
  .contact .localisation_container {
    display: flex;
  }

  .contact .map {
    display: block;
    width: 80vw;
    padding-left: 0;
    margin: 0 auto;
  }

  .contact .contact_aside {
    position: relative;
    top: 0;
    right: 0rem;
    width: 100vw;
    background-color: #cb1411;
    padding-left: 0rem;
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .contact {
    position: relative;
    padding-top: 5rem;
  }
}
