.menu_list_container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}

.menu_list_container:not(:last-of-type) {
  margin-bottom: 7rem;
}

.menu_list_container.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.menu_list_container.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  width: 75%;
  column-gap: 20rem;
  row-gap: 3rem;
}

.menu_list_container.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  gap: 3rem;
  width: 75%;
  column-gap: 15rem;
}

.menu_list_title,
.menu_list_secondaryTitle {
  font-size: 3.4rem;
  padding: 0rem 0 4rem 0;
  text-decoration: underline;
  font-weight: 300;
  text-align: center;
}

.menu_list_secondaryTitle {
  font-size: 2.4rem;
}

@media (max-width: 1300px) {
  .menu_list_container.grid-2 {
    column-gap: 10rem;
  }

  .menu_list_container.grid-3 {
    column-gap: 5rem;
  }
}

@media (max-width: 1100px) {
  .menu_list_container {
    max-width: 90%;
  }

  .menu_list_container.grid-2 {
    column-gap: 5rem;
    width: 95%;
  }

  .menu_list_container.grid-3 {
    width: 95%;
  }
}

@media (max-width: 900px) {
  .menu_list_container {
    max-width: 90%;
  }
  .menu_list_container.grid-2 {
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
  }

  .menu_list_container.grid-3 {
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .menu_list_container.grid-3 {
    width: 50%;
    grid-template-columns: repeat(1, 1fr);
  }

  .menu_list_container:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  .menu_list_title {
    font-size: 3rem;
  }
}
