@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  font-family: "Lato", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  list-style: none;
}

a {
  color: #fff;
}
