.gallerie_img_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
}

.gallerie_title {
  text-align: center;
  font-size: 5rem;
  padding: 10rem 0 2rem 0;
  text-transform: uppercase;
  font-weight: 300;
}

.gallerie_title span {
  font-style: italic;
  text-transform: capitalize;
}

.gallerie_img_container img {
  width: 100%;
  height: auto;
}

@media (max-width: 900px) {
  .gallerie {
    display: none;
  }
}
