.menu {
  padding-top: 10rem;
}

.menu .menu_title {
  text-align: center;
  font-style: italic;
  font-weight: 300;
  font-size: 5rem;
  padding-bottom: 4rem;
}

@media (max-width: 600px) {
  .menu {
    padding-top: 6rem;
  }
}
