.container {
  background-color: #c8211b;
  display: flex;
  justify-content: space-between;
  height: 4rem;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.info {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: white;
}

.info:first-child {
  margin-left: 5rem;
}

.info a {
  color: white;
}

.info i {
  padding: 0 1rem 0 2.5rem;
}

.info p:first-of-type i {
  padding-left: 0;
}

.social {
  display: flex;
  align-items: center;
}

.social:last-child {
  margin-right: 5rem;
}

.social a i {
  font-size: 2rem;
  padding: 1rem;
  display: inline-block;
  color: white;
}

.social a:last-child {
  margin-left: 2rem;
}

@media (max-width: 1100px) {
  .container {
    display: none;
  }
}
