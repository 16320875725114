.hero {
  background: center/cover no-repeat url("../images/piment-2.jpg");
  width: 100%;
  height: 100vh;
  position: relative;
}

.hero_paragraph {
  position: absolute;
  top: 25rem;
  margin-left: 10rem;
}

.hero_paragraph h2 {
  font-size: 5rem;
  font-style: italic;
  font-weight: 400;
}

.hero_paragraph h1 {
  font-size: 8rem;
  color: white;
  font-weight: 400;
}

.hero_paragraph p {
  font-size: 2.5rem;
  font-weight: 400;
  padding: 0.5rem 0;
}

.hero i {
  font-size: 4rem;
  position: absolute;
  color: white;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  animation: arrow 2s ease-in-out infinite;
  transition: opacity 0.3s;
}

@keyframes arrow {
  0% {
    transform: translateY(-5rem);
  }

  50% {
    transform: translateY(0rem);
  }

  100% {
    transform: translateY(-5rem);
    opacity: 0;
  }
}

@media (max-width: 1100px) {
  .hero_paragraph {
    top: 10rem;
  }

  .hero_paragraph h1 {
    font-size: 6rem;
  }
}

@media (max-width: 900px) {
  .hero_paragraph {
    top: 9rem;
    margin-left: 4rem;
  }

  .hero_paragraph h1 {
    font-size: 4rem;
  }

  .hero_paragraph h2 {
    font-size: 3rem;
  }

  .hero_paragraph p {
    font-size: 2rem;
  }
}

@media (max-width: 900px) {
  .hero_paragraph {
    margin-left: 2rem;
  }
}

@media (max-width: 600px) {
  .hero_paragraph {
    top: 13rem;
  }

  .hero i {
    bottom: 4rem;
  }
}

@media (max-width: 500px) {
  .hero_paragraph h1 {
    font-size: 3rem;
  }

  .hero_paragraph p {
    font-size: 1.5rem;
  }
}
