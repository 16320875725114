.item_container {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
  max-width: 60rem;
  justify-content: flex-start;
}

.item_img {
  width: 100%;
  max-width: 6rem;
}

.item_img img {
  width: 100%;
  border-radius: 1rem;
  padding-top: 0.4rem;
}

.item_description {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.item_description h3 {
  font-size: 1.8rem;
}

.item_description p {
  font-size: 1.2rem;
}

.item_price {
  align-self: flex-start;
  font-size: 2rem;
  font-weight: bold;
  min-width: 5.5rem;
  margin-left: auto;
  text-align: right;
}

.item_moreInfo {
  color: #c8211b;
  font-weight: bold;
}

@media (max-width: 1100px) {
  .item_description h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  .item_price {
    font-size: 1.5rem;
  }
}
