.footer_container {
  background-color: #262626;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5rem 0;
}

.footer_container .block h3 {
  font-size: 2rem;
  padding-bottom: 2rem;
}

.footer_container .block p {
  font-size: 1.4rem;
  line-height: 2rem;
  padding-bottom: 1rem;
}

.footer_container .block p:last-child {
  padding-bottom: 0;
}

.footer_container .block p i {
  padding-right: 0.4rem;
}

.footer_container .block p a {
  transition: color 0.4s;
}

.footer_container .block p a:hover {
  color: #c8211b;
}

@media (max-width: 900px) {
  .footer_container {
    flex-wrap: wrap;
    gap: 3rem;
  }
}

@media (max-width: 500px) {
  .footer_container {
    flex-direction: column;
  }

  .footer_container .block {
    width: 100%;
  }
}
