.navbar {
  height: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 5rem;
  z-index: 10;
  transition: all 0.3s;
}

.scrolled_navbar {
  background-color: #fff;
  top: 0;
}

.navbar ul {
  display: flex;
  align-items: center;
}

.navbar img {
  height: 5rem;
  margin-left: 6rem;
}

.navbar a {
  color: black;
  display: block;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 2rem;
  padding: 2rem;
  transition: transform 0.4s;
  text-shadow: 0.4px 0.4px 0.4px black;
}

.navbar a:hover,
.navbar a.active {
  transform: scale(1.4);
}
.navbar ul:last-child {
  margin-right: 4rem;
}

@media (max-width: 1100px) {
  .navbar {
    height: 5rem;
    top: 0rem;
  }

  .navbar img {
    height: 4rem;
  }

  .navbar a {
    font-size: 1.4rem;
  }
}

@media (max-width: 900px) {
  .navbar img {
    display: none;
  }

  .navbar ul {
    margin: 0 auto;
  }

  .navbar ul li:nth-child(3) {
    display: none;
  }

  .navbar ul:last-child {
    margin-right: auto;
  }

  .navbar a.active {
    transform: scale(1);
  }

  .navbar a:hover {
    transform: scale(1.4);
  }
}

@media (max-width: 500px) {
  .navbar a {
    margin-right: 0;
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    font-size: 1.2rem;
  }
}
